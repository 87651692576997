import { apiVersion } from "./../../node_modules/aws-sdk/clients/amp.d";
import AWS from "aws-sdk";

AWS.config.update({
  region: process.env.VUE_APP_REGION || "ap-northeast-1",
  credentials: {
    accessKeyId: process.env.VUE_APP_S3_ACCESS_KEY_ID || "",
    secretAccessKey: process.env.VUE_APP_S3_SECRET_ACCESS_KEY || "",
  },
});

// When no region or credentials are provided, the SDK will use the
// region and credentials from the local AWS config.
const s3Client = new AWS.S3({ apiVersion: "2006-03-01", params: { Bucket: process.env.VUE_APP_S3_BUCKET_NAME } });

export const uploadToS3 = (file: any) => {
  return new Promise((resolve, reject) => {
    const timestamp = new Date().getTime();
    const filename = "file_" + timestamp;
    const params: any = {
      Bucket: process.env.VUE_APP_S3_BUCKET_NAME,
      Key: filename,
      ContentType: file.type,
      Body: file,
    };
    s3Client.upload(params, function (err: any, data: any) {
      if (err) {
        console.log(err);
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
};

export const getS3Url = (key: string) => {
  return new Promise((resolve, reject) => {
    const params: any = {
      Bucket: process.env.VUE_APP_S3_BUCKET_NAME,
      Key: key,
      Expires: 60 * 60, // 有効期限（秒）
    };

    const url = s3Client.getSignedUrl("getObject", params);
    resolve(url);
  });
};

export const deleteFile = (key: string) => {
  const params: any = {
    Bucket: process.env.VUE_APP_S3_BUCKET_NAME,
    Key: key,
  };
  s3Client.deleteObject(params, function (err: any, data: any) {
    if (err) {
      console.log(err);
    } else {
      return data;
    }
  });
};
